/*!*modal start*!*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    gap: 10px;

    z-index: 5;
}

.modal-container {
    position:fixed;
    background: var(--bg-color);
    /*width: 80%;*/
    /*height: 70%;*/
    max-width: 500px;
    max-height: 500px;
    /*height: 70%;*/
    padding: 20px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    display: flex;
    flex-direction: column;
    /*max-height: 100%;*/

    /*color: #8a8a8a; !* Light text *!*/
    /*background-color: #0c0c0c; !* Dark background *!*/
    border: solid 1px var(--darker-secondary-color);
}

.modal-container-inner {
    overflow-y: auto;
    height: 100%;
}

.modal-actions {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
/*!*modal end*!*/
