/*!*modal start*!*/
.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.6);*/
    /*background: rgba(255, 0, 0, 0.6);*/
    background-color: var(--fullscreen-background-lighter2);
    display: flex;
    /*flex-direction: column;*/
    /*gap: 10px;*/

    z-index: 1002;
}

@media (max-width: 768px) {
    .modal {
        background-color: var(--fullscreen-background-lightener);
    }
}

.modal-nonblocking {
    position: fixed;
    top: -1px;
    left: -1px;
    /*width:100%;*/
    /*height: 100%;*/
    /*background-color: var(--fullscreen-background-lighter2);*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 10px;*/

    z-index: 1001;
}



.modal-container {
    position:fixed;
    background: var(--bg-color);
    width: fit-content;
    height: fit-content;
    min-width: 200px;
    max-height:  100%;
    max-width: 100%;
    /*height: 70%;*/
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    left: -9999px; /* Initially offscreen */
    top: -9999px; /* Initially offscreen */
    /*top:50%;*/
    /*left:50%;*/
    /*transform: translate(-50%,-50%);*/
    display: flex;
    flex-direction: column;
    /*max-height: 100%;*/
    /*color: #8a8a8a; !* Light text *!*/
    /*background-color: #0c0c0c; !* Dark background *!*/
    border: solid 1px var(--border-color);
    /*border-radius: 10px;*/
    /*box-shadow: var(--shadow-start) 0px 0px 15px, var(--shadow-end) 0px 0px 3px 1px;*/
    z-index: 1000;
}

/*.modal-container-maximize {*/
/*    position:fixed;*/
/*    background: var(--bg-color);*/
/*    !*background: blue;*!*/
/*    !*width: 100%;*!*/
/*    !*height: 100%;*!*/
/*    top: 50px; !* Adjust this value to increase or decrease the top space *!*/
/*    width: 100vh;*/
/*    height: calc(100vh - 50px); !* Subtract the same amount to maintain full height *!*/
/*    !*padding-block-start: 5%;*!*/
/*    !*max-width: 500px;*!*/
/*    !*max-height: 500px;*!*/
/*    !*padding: 50px;*!*/
/*    !*top:0px;*!*/
/*    !*left:0px;*!*/
/*    !*transform: translate(-50%,-50%);*!*/
/*    padding: 0px;*/
/*    box-sizing: border-box;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    !*max-height: 100%;*!*/

/*    !*color: #8a8a8a; !* Light text *!*!*/
/*    !*background-color: #0c0c0c; !* Dark background *!*!*/
/*    !*border: solid 1px var(--darker-secondary-color);*!*/
/*}*/

.modal-fullscreen {
    position: fixed;
    background: var(--bg-color);
    top: 60px; /* Adjust this value to increase or decrease the top space */
    width: 100%;
    height: calc(100% - 60px); /* Subtract the same amount to maintain full height */
    border-top: dashed 1px var(--border-color);
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
    /*top: 0;*/
    left: 0;
    /*width: 100%;*/
    /*height: 100%;*/
    /*width: 100vw;*/
    /*height: 100vh;*/
    /*overflow: auto;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}


/*.maximize {*/
/*    padding: 50px !important;*/
/*    width: 100% !important;*/
/*    height: 100%  !important;;*/
/*    max-width: unset !important;*/
/*    max-height: unset !important;*/
/*    z-index: 2000;*/
/*    !*z-index: 30;*!*/
/*}*/

.modal-nav {
    position: fixed;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    z-index: 1010;
    /*margin-bottom: 10px;*/
}

.modal-container-inner {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modal-actions {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
/*!*modal end*!*/
