/*!* src/tab/Tab.css *!*/

/*.top-tab-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

.left-container-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--fullscreen-background-lightener);
    /*background: #1DE83A44;*/
    z-index: 10;
}

/*.closed .left-container-overlay {*/
/*    display: none;*/
/*}*/

/*@media (min-width: 769px) {*/
/*    .left-container-overlay {*/
/*        display: none;*/
/*    }*/
/*}*/


@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.left-container-top {
    /*z-index: 10;*/
    z-index: 20;
    position: relative;
    background-color: var(--bg-color);
    /*display: flex;*/
    /*flex-direction: row;*/
    /*height: 100%;*/
    /*gap:5px;*/
    /*padding: 5px 5px 5px 5px;*/
    width: 222px;
    /*transition: width 0.3s ease-out;*/
    /*transition: opacity 0.3s ease-out;*/
    /*overflow: hidden;*/
    /*animation: slideInFromLeft 0.2s ease-out forwards;*/
    border-right: solid 1px var(--border-color);
}

@media (max-width: 768px) {
    .left-container-top {
        /*border-right: solid 1px var(--secondary-color);*/
        background-color: var(--bg-color);
        /*animation: slideInFromLeft 0.3s ease-out forwards;*/
        position: fixed;
        /*top: 59px;*/
        /*padding-top: 50px;*/
        width: 250px;
        left: 0;
        top: 0;
        /*z-index: 20;*/
        /*transition: width 0.3s ease-out;*/
        /*width: 100%;*/
        height: 100%;
        overflow-y: hidden;  /*TEST*/
        /*padding: 1% 0%;*/
        opacity: 1;
        /*transition: opacity 0.3s ease-out;*/
    }
}

/*    .left-container-top {*/
/*        transition: transform 0.3s ease-out;*/
/*        transform: translateX(-100%);*/
/*        !*transform: translateX(0);*!*/
/*    }*/

/*    !*.left-container-top.open {*!*/
/*    !*    transform: translateX(0);*!*/
/*    !*}*!*/

/*}*/

.left-container-top.closed {
    width: 0;
    opacity: 0;
}


.right-container-top {
    /*flex: 1;*/
    /*padding: 10px 5px 5px 20px;*/
    /*padding: 0px 5px;  !*TODO: validar se não quebra*!*/
    width: 100%;
    background-color: var(--bg-secondary-color);
    /*transition: flex-grow 0.3s ease-out;*/
    /*animation: slideInFromLeft 0.5s ease-out forwards;*/
/*TODO:TEST*/
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .right-container-top {
        /*flex: 1;*/
        /*padding: 10px 5px 5px 20px;*/
        padding: unset;
    }
}


.tab-routes {
    /*height: 100%;*/
}

.nav-top-container {
    /*padding: 10px;*/
    position: relative;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    /*height: calc(100vh - var(--topnav-shift-height));*/
    /*width: 200px;*/
    height: 100%;
    box-sizing: border-box;
    padding-top: 16px;
    gap: 10px;
    /*height: 100vh; !* Full height of the viewport *!*/
    overflow: hidden; /* Hide any content that goes beyond the container */
    transition: width 0.3s ease-out;

}

@media (max-width: 768px) {
    .nav-top-container {
        /*height: unset;*/
        /*width: 250px;*/
        /*border-right: solid 1px var(--secondary-color);*/
    }
}

.closed .nav-top-container  {
    /*padding: 10px;*/
    /*position: relative;*/
    /*z-index: 2;*/
    /*background-color: var(--bg-color);*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*height: calc(100vh - var(--topnav-shift-height));*/
    width: 0;
    /*transition: width 0.3s ease-out;*/
    /*height: 100%;*/
    /*box-sizing: border-box;*/
    /*padding-top: 16px;*/
    /*gap: 10px;*/
    /*!*height: 100vh; !* Full height of the viewport *!*!*/
    /*overflow: hidden; !* Hide any content that goes beyond the container *!*/
}



.tab-nav-top {
    flex: 0 0 auto; /* This will not grow or shrink and will be automatically sized based on its content */
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    gap: 5px;
    /*padding: var(--padding-default);*/
    align-items: flex-start;
}

.tab-nav-project-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /*justify-content: flex-end;*/
}

.tab-nav-project {
    /*flex: 0 0 auto; !* This will not grow or shrink and will be automatically sized based on its content *!*/
    cursor: pointer;
    padding: 4px 8px;
    font-weight: bold;
    /*color: var(--primary-color);*/
    font-size: 1.2rem;
    max-width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.tab-nav-project:hover {
    background-color: var(--bg-button-hover-color);
    border-radius: 4px;
    /*color: var(--primary-color);*/
}

.tab-nav-project img {
    height: 12px;
}


.tab-nav-middle {
    flex: 1 1 auto; /* This will grow and shrink as needed, taking up any remaining space */
    overflow-y: hidden; /* Add scroll if content overflows */
    z-index: auto;
}

.tab-nav-bottom {
    flex: 0 0 auto; /* This will not grow or shrink and will be automatically sized based on its content */
    margin-top: auto; /* Pushes the div to the bottom */
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    margin-bottom: 10px;
}

.tab-pages {
    padding: 10px;
    background-color: var(--bg-secondary-color);
    /*padding: var(--padding-default);*/
    /*margin: 10px;*/
    /*height: 100%;*/
    flex: 6;
}

/*.theme img {*/
/*    height: 30px;*/
/*    margin-right: 30px;*/
/*}*/
/*.theme:hover img {*/
/*    transform: scale(1.2);*/
/*}*/


.show-nav {
    position: fixed;
    cursor: pointer;
    left: 5px;
    top: 5px;
}
.hide-nav {
    /*position: relative;*/
    /*height: 0px;*/
    display: flex;
    justify-content: flex-end;
    height: 0px;
    /*right: 0px;*/
    cursor: pointer;
}


.project-switcher-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20px; /* Adjust this value to your needs */
}

.project-switcher {
    padding: 4px 0px;
    margin-right: 2px;
    /*border-radius: 4px;*/
    max-width: 200px;
    border-color: var(--border-color)
}

.project-menu-item {
    /*margin-left: 20px;*/
}

.workspace-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

}

.workspace-select-container {
    display: flex;
    flex-direction: row;
    /*justi*/
    gap: 10px;
    align-items: center;
}

.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;

    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*gap: 20px;*/
    /*width: 100%;*/
}

.logout-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;
}


.balance-div {
    /*font-size: smaller;*/
    opacity: .65;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.balance {
}

.ranking-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px;
    max-height: 100px;
    overflow-y: auto;
    /*width: 100%;*/
    padding-right: 10px;
}

.ranking-content > .name {
}

@media only screen and (max-width: 768px) {
    .ranking-content {
        max-height: unset;
    }
}

.ranking-line {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    grid-column-gap: 4px; /* Adjust the value to your preference */
}
.ranking-line > :last-child {
    justify-self: end;
}

.ranking-line > :nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ranking-line  img {
    height: 20px;
    width: 20px;
}

.medal-number {
    background-color: var(--secondary-color);
    border: 1px solid var(--border-color); /* Border color */
    border-radius: 50%; /* Makes the shape a circle */
    color: var(--bg-color); /* Text color */
    font-size: 0.8rem; /* Size of the number */
    /*font-weight: bold; !* Makes the number bold *!*/
    height: 16px; /* Height of the circle */
    width: 16px; /* Width of the circle */
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    /*margin: 5px; !* Optional: space around the circle *!*/
}

.title-with-icon {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}
