.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    /*min-height: 400px;*/
    /*min-width: 600px;*/
    flex: 1;
    width: 100%;
}

.workspace {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .workspace {
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
    }
}

.workspace-left {
    display: flex;
    flex: 1;
    /*min-width: 40%;*/
    flex-direction: column;
}

.workspace-right {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.system-message-edit-space {
    /*height: 100%;*/
    /*width: 100%;*/
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: solid 1px var(--border-color);
    background-color: var(--bg-chat-input);
}

.system-message-textarea {
    /*height: 100%;*/
    /*width: 100%;*/
    flex: 1;
    border: unset;
}

.prompt-container {
    height: 40%;
    border-top: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    display: flex;
    padding: 10px;
    background-color: var(--bg-chat-input);
}

@media only screen and (max-width: 768px) {
    .prompt-container {
        border: solid 1px var(--border-color);
    }
}

.prompt-textarea {
    flex: 1;
    border: unset;
}

.chat-output-container {
    /*height: 60%;*/
    flex: 1;
    /*border: dotted 1px var(--border-color);*/
    border-top: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    border-bottom: solid 1px var(--border-color);
    overflow: hidden; /* Prevent content from overflowing */
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.chat-output-content {
    max-width: 100%; /* Prevent inner container from exceeding parent's width */
    max-height: 100%; /* Prevent inner container from exceeding parent's height */
    overflow: auto; /* Add scrollbars if content overflows */
    box-sizing: border-box; /* Include padding and borders in size calculations */
    /*height: 100%;*/
    /*overflow-y: auto;*/
    /*flex: 1;*/
}

@media only screen and (max-width: 768px) {
    .chat-output-container {
        border: solid 1px var(--border-color);
    }
}



.input-view-icon {

}

.panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.workspace-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.candidate-window {
    position: fixed;
    left: 50%;
    top: 70px;
    right: 0%;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-darker);
    border: solid 1px var(--border-color);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .candidate-window {
        left: 0;
        top: 0;
        right: 0;
        bottom: 30%
    }
}

.candidate-output-container {
    /*flex: 1;*/
    flex-grow: 1;
    overflow-y: auto;
    /*margin: 10px;*/
}

.candidate-panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.title-panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.title-panel:first-child {
    flex: 1;
}
.title-text {
    min-width: 400px;
}

@media only screen and (max-width: 768px) {
    .title-text {
        min-width: unset;
    }
}

.loading-window {
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;
}

.title-panel-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
}
