.info-message {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
    /*color: white;*/
    /*margin: 10px;*/
    /*border-radius: 5px;*/
    z-index: 9999;
    opacity: 1;
    transition: opacity 2s ease-in-out;
    /*word-wrap: break-word;*/
}

.inner-div {
    color: var(--bg-color);
    margin: 8px;
    font-size: var(--font-size-default);
}


@media only screen and (max-width: 768px) {
    .info-message {
        width: 100%;
        word-wrap: break-word;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
.error-message.fade-out {
    opacity: 0;
}
