/*!*modal start*!*/
.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    height: 100%;
    background-color: var(--fullscreen-background-lighter);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1001;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background: var(--bg-color);
    max-height: 100%;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    border: solid 1px var(--border-color);
    /*border-radius: 8px;*/
}

.modal-container-maximize {
    position:fixed;
    background: var(--bg-color);
    width: 100%;
    height: 100%;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.modal-nav {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.modal-container-inner {
    overflow-y: auto;
    height: 100%;
}

.modal-actions {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
}

.display-block {
    /*display: block;*/
}

.display-none {
    display: none;
}
/*!*modal end*!*/
