
/*dark mode*/
:root {
    --primary-color: #ffffff;
    --lighter-secondary-color: #b3b3b3;
    --agent-progress-color: #ffc66d;
    --icon-color: #d5d3d3;
    --icon-color-lighter: #d0d0d0;
    --icon-check-color:#17FB37;
    --attention-bar-bgcolor: #17FB37;
    --secondary-color: #bababa; /*#a5a3a3;*/
    --input-color: #bababa; /*#a5a3a3;*/
    /*--secondary-color: #e2e2e2; !*#a5a3a3;*!*/
    /*--secondary-color: #cacaca; !*#a5a3a3;*!*/
    --lightenerer-color: #b9b9b9ff;
    --lightener-color: #b9b9b9;
    --darker-secondary-color: #4f4f4f;
    --chat-user-color: #313030;
    --delete-color: #d16464;
    --delete-hover-color: #e33939;
    --attention-button-color: #8f1a1a;
    --attention-button-hover-color: #ff9494;
    --premium-button-color: #ff9494;
    --bg-button-color: #383d45;
    --bg-button-hover-color: rgba(67, 75, 81, 0.72);
    --bg-selection-item-hover-color: rgba(41, 44, 49, 0.72);
    --primary-button-color: #ffffff;
    --bg-darker-color: #16222f;
    --bg-color: #000000;
    --bg-icon-blue: #1d9bf0;
    --bg-icon-gray: #383d45;
    --icon-color-white: #ffffff;
    /*--bg-color: #1e1f22;*/
    --bg-secondary-color: #131414; /* #222327;*/
    --bg-secondary-color-2: #262729;
    --bg-lighter-color: #586271;
    --bg-lightener-color: #8a929f;
    --bg-lightenerer-color: #b1bccf;
    --bg-prompt-activate: #282a2c;
    /*--bg-chat-system: #959595;*/
    --bg-chat-system: #373737; /*#535353; /*#535353; /*#696969;*/
    /*--bg-chat-user: #313030; !*#3f3f3f;*!*/
    --bg-chat-user:#313030;
    --bg-chat-assistant: #000000;
    --bg-chat-input: #131414;
    --bg-darker: #414347;
    --bg-darker-2: #313437;
    --border-color: #414245;
    --border-attention: #ffe26d;
    --main-color: #92adda;
    --main-color-alternative: #ef8904;
    --main-color-light: #59606c;
    --topnav-height: 50px;
    --fullscreen-background: rgba(0, 0, 0, 0.9);
    /*--fullscreen-background-noopacity: rgba(0, 0, 0, 0.9);*/
    --fullscreen-background-lighter: rgba(0, 0, 0, 0.85);
    --fullscreen-background-lighter2: rgba(0, 0, 0, 0.2);
    --fullscreen-background-lighter5: rgba(0, 0, 0, 0.5);
    --fullscreen-background-lightener: rgba(140, 140, 140, 0.6);
    --console-background: rgba(27, 28, 30, 0.85);
    --shadow-start: rgba(255, 255, 255, 0.2);
    --shadow-end: rgba(255, 255, 255, 0.2);
    --font-size-default: 0.87rem;
    --font-size-chat-title: 0.92rem;
    --font-size-code: 0.83rem;
    --font-size-small: 0.68rem;
    --font-size-small-xxx: 0.3rem;
    --color-orandish: #81673e;

    --fonts-segoe: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --fonts-arial: Arial, "Helvetica Neue", Helvetica, "Liberation Sans", "Noto Sans", "Nimbus Sans L", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --fonts-mono: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.dark {
    /*--bg-darker-color: var(--bg-darker-color);*/
    /*--bg-color: var(--bg-color);*/
    /* define all your colors for dark theme */
}

.light {
    --primary-color: #000000;
    --lighter-secondary-color: #1f1f2a;
    --agent-progress-color: #f00df1;
    --icon-color: #626161;
    --icon-color-lighter: #333232;
    --icon-check-color:#1DE83A;
    --attention-bar-bgcolor: #1DE83A;
    /*--secondary-color: #2e2e3b;*/
    --secondary-color: #1A1A1A;
    --input-color: #1A1A1A; /*#a5a3a3;*/
    --lightenerer-color: #232323;
    --lightener-color: #383d4d;
    --darker-secondary-color: #212121;
    --chat-user-color: #151921;
    --delete-color: #f53636;
    --delete-hover-color: #f84444;
    --attention-button-color: #f53636;
    --attention-button-hover-color: #f54747;
    --premium-button-color: #f53636;
    --primary-button-color: #ffffff;
    --bg-button-color: #064fdc;
    --bg-button-hover-color: rgba(133, 161, 224, 0.42);
    --bg-selection-item-hover-color: rgba(133, 161, 224, 0.42);
    --bg-darker-color: #dce6fc;
    /*--bg-color: #ffffff;*/
    --bg-color: #f7f8fa;
    --bg-icon-blue: #064fdc;
    --bg-icon-gray: #383d4d;
    --icon-color-white: #ffffff;
    --bg-prompt-activate: #e7ecf4;
    /*--bg-color: #f7f8fa;*/
    /*--bg-secondary-color: #eef2fc;*/
    /*--bg-secondary-color: #fcfcfc;*/
    /*--bg-secondary-color: #eeefff;*/
    --bg-secondary-color: #f7f8ff;
    --bg-secondary-color-2: #dae0f1;
    --bg-lighter-color: #e3e3e3;
    --bg-lightener-color: #fcefef;
    --bg-lightenerer-color: #d7dbfd;
    --bg-chat-system: #c1c1fd;
    --bg-chat-user: #e5e5ff;
    --bg-chat-assistant: #ffffff;
    --bg-chat-input: #ffffff;
    --bg-darker: #b0c4ff;
    --bg-darker-2: #d6e0fa;
    --border-color: #bcc2d1;
    --border-attention: #f70af8;
    --main-color: #3866ff;
    --main-color-light: #698bf8;
    --main-color-alternative: #ef048f;
    --fullscreen-background: rgba(255, 255, 255, 0.9);
    --fullscreen-background-lighter: rgba(255, 255, 255, 0.5);
    --fullscreen-background-lighter2: rgba(255, 255, 255, 0.2);
    --fullscreen-background-lighter5: rgba(255, 255, 255, 0.5);
    --fullscreen-background-lightener: rgba(10, 10, 10, 0.6);
    --console-background: rgba(255, 255, 255, 0.85);
    --shadow-start: rgba(0, 0, 0, 0.2);
    --shadow-end: rgba(0, 0, 0, 0.15);

    --color-orandish: #b17c28;

}

::-webkit-scrollbar,::-webkit-scrollbar-corner {
    background: transparent;
    height: 8px;
    width: 6px
}

::-webkit-scrollbar-thumb {
    /*background: content-box currentColor;*/
    background-color: var(--border-color); /* Color of the scrollbar thumb */

    /*background: content-box var(--bg-lightenerer-color);*/
    /* border:2px solid transparent; */
    border-radius: 8px;
    color: #dadce0;
    min-height: 48px;
    min-width: 48px
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary-color); /* Cor branca quando o mouse passa por cima */
}


/*!*light*!*/
/*:root {*/
/*    --primary-color: #000000;*/
/*    --secondary-color: #333333;*/
/*    --bg-color: #f5f5f5;*/
/*    --main-color: #2250f6;*/
/*}*/

/*body {*/
/*    height: 100%;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*    background-color: var(--bg-color);*/
/*    color: var(--primary-color);*/
/*}*/

/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*    color: var(--main-color);*/
/*}*/


html {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    /*font-family: sans-serif, Helvetica;*/
    /*font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica;*/
    /*font-family: ui-sans-serif, sans-serif;*/
    /*font-family: Segoe UI;*/
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    /*font-family: Arial, "Helvetica Neue", Helvetica, "Liberation Sans", "Noto Sans", "Nimbus Sans L", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    font-family: var(--fonts-segoe);

    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
}


body {
    overflow: hidden;
    background-color: var(--bg-color);
    color: var(--primary-color);

    height: 100%;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-default);
}


@media only screen and (max-width: 768px) {
    body {
        font-size: 16px;    /* less than this in mobile will be zoomed */
        -webkit-text-size-adjust: 100%;   /* prevent the browser in mobile increase the font automatically */

        height: unset !important;
        min-height: 100%;
        overflow: auto;
    }
}


button, select {
    font-size: var(--font-size-default); /* This makes the font size the same as the parent element */
}

.code {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
    font-size: var(--font-size-code);
    /*white-space: pre-wrap;*/
    white-space: pre-wrap;
    word-break: break-word;

    /*white-space: pre-line;*/
}

.font-mono {
    font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/*@media only screen and (max-width: 768px) {*/
/*    .code {*/
/*        font-size: 16px;    !* less than this in mobile will be zoomed *!*/
/*    }*/
/*}*/

.code-view-in-chat {
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
    /*font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica;*/
    /*font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Apple Color Emoji, Arial, 'Segoe UI Emoji', 'Segoe UI Symbol';*/
    font-size: var(--font-size-code);
    /*white-space: pre-wrap;*/
    /*white-space: pre-line;*/
    white-space: pre-wrap;
    word-break: break-word;
}

/*for inside markdown*/
.code-view-in-chat ul,
.code-view-in-chat ol {
    white-space: normal; /* or initial, depending on your needs */
}


@media only screen and (max-width: 768px) {
    .code-view-in-chat {
        font-size: 1rem;    /* less than this in mobile will be zoomed */
    }
}

.code-view {
    font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
    font-size: var(--font-size-code);
    /*white-space: pre-wrap;*/
    /*white-space: pre-line;*/
    white-space: pre-wrap;
    word-break: break-all;
}

/*for inside markdown*/
.code-view ul,
.code-view ol {
    white-space: normal; /* or initial, depending on your needs */
}


@media only screen and (max-width: 768px) {
    .code-view {
        font-size: 1rem;    /* less than this in mobile will be zoomed */
    }
}

@media only screen and (max-width: 768px) {
    .code {
        font-size: 1rem;    /* less than this in mobile will be zoomed */
    }
}

/*The browser is increasing the font in ladspace mode. Didn't worked */
/*@media (max-width: 768px) and (orientation: landscape) {*/
/*    .code-view {*/
/*        font-size: 16px;    !* less than this in mobile will be zoomed *!*/
/*    }*/
/*}*/

/*@media screen and (orientation: landscape) {*/
/*    body {*/
/*        font-size: 16px;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 768px) {*/
/*    .code {*/
/*        font-size: 16px;    !* less than this in mobile will be zoomed *!*/
/*    }*/
/*}*/


#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    /*padding: 0px 5px;*/
}

hr {
    border: none;
    height: 1px;
}

hr.separator {
    border-bottom: solid 1px var(--border-color);
}


a {
    color: var(--main-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/*h1, h2, h3, h4, h5, h6 {*/
/*    color: var(--primary-color);*/
/*}*/

h1 {
    font-size: 2.25rem;
}

h2 {
    font-size: 1.50rem;
}

h3 {
    font-size: 1.25rem;
}


p, span, div {
    color: var(--secondary-color);
}

p {
    margin: 0;
}

/*h3 {*/
/*    margin-block-start: 1em;*/
/*    margin-block-end: 0em;*/
/*}*/
/*ul, blockquote {*/
/*    margin-block-start: 0em;*/
/*    margin-block-end: 0em;*/
/*}*/


textarea {
    background-color: var(--bg-chat-input);
    color: var(--input-color);
    border-color: var(--border-color);
    box-sizing: border-box;
}
textarea:focus {
    /*border-color: var(--border-color);*/
    outline: none;
}


input:focus {
    outline: none !important;
    border-color: var(--border-color);
    /*border-color: #719ECE;*/
    /*box-shadow: 0 0 2px #719ECE;*/
}

/*textarea:focus {*/
/*    outline: none !important;*/
/*    border-color: var(--border-color);*/
/*    box-shadow: 0 0 4px #719ECE;*/
/*}*/


@media only screen and (max-width: 768px) {
    textarea {
        font-size: 16px;    /* less than this in mobile will be zoomed */
    }
}


/*input {*/
/*    background-color: var(--bg-color);*/
/*    color: var(--lightener-color);*/
/*}*/

@media only screen and (max-width: 768px) {
    input {
        font-size: 16px;    /* less than this in mobile will be zoomed */
    }
    button {
        font-size: 1.0em;    /* less than this in mobile will be zoomed */
    }
    select {
        font-size: 1.0em;    /* less than this in mobile will be zoomed */
    }
}


/*input[type="text"] {*/
/*    padding: 4px 10px;*/
/*    border: 1px solid var(--border-color);*/
/*    !*margin: 4px;*!*/
/*}*/

input {
    /*width: 100%;*/
    font-size: unset;
    padding: 4px 10px;
    border: unset;
    background-color: var(--bg-chat-input);
    /*color: unset;*/
    color: var(--input-color);
}

input:focus {
    border: unset;
}

.input, .input:focus {
    border: solid 1px var(--border-color);
}


.button {
    background-color: var(--bg-button-color);
    color: var(--primary-button-color);
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    width: fit-content;
    font-size: var(--font-size-default);
}

.button:hover {
    background-color: var(--bg-button-hover-color);
    /*border-radius: 50%;*/
    /*color: var(--bg-color);*/
}

button:disabled {
    /* CSS styles for when the button is disabled */
    opacity: 0.5;
    cursor: not-allowed;
}

.inline-button {
    background-color: var(--bg-button-color);
    color: var(--primary-button-color);
    padding: 4px 8px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    margin: 0px 4px;
}

.inline-button img {
    height: 15px;
}

button {
    background: none;
    border: unset;
}

.inline-button:hover {
    background-color: var(--bg-button-hover-color);
    /*color: var(--bg-color);*/
}

/*select, option {*/
/*    background-color: var(--bg-color);*/
/*    color: var(--primary-color);*/
/*}*/



select {
    padding: 10px 4px;
}

select, option {
    background-color: var(--bg-chat-input);
    color: var(--input-color);
    border-color: var(--border-color);
}



button.delete {
    background-color: var(--delete-color);
}
button.delete:hover {
    background-color: var(--delete-hover-color);
}
a.delete {
    color: var(--delete-color);
}
a.delete:hover {
    color: var(--delete-hover-color);
}

.button-no-background, .button-no-background:hover {
    background: none;
}

.icon-button-with-text {
    height: 24px;
    padding: 12px 12px 12px 2px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.icon-button-with-text img {
    height: 100%;
}

.icon-button-with-text:hover {
    /*background-color: var(--bg-button-hover-color);*/
    /*border-radius: 50%;*/
    /*transform: scale(1.2);*/
}


/*TEST*/
.pointer-icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer-icon-no-width {
    cursor: pointer;
    height: 20px;
    padding: 5px 7px;
    border-radius: 4px;
    /*background-color: var(--bg-darker-2);*/
    align-items: center;
}
.pointer-icon-no-width:hover {
    background-color: var(--bg-button-hover-color);
    border-radius: 4px;
}


.pointer-icon-small {
    cursor: pointer;
    heigth: 20px;
    width: 20px;
    padding: 4px;
}

.pointer-icon img, .pointer-icon-small img {
    heigth: 100%;
    width: 100%;
}

.pointer-icon:hover, .pointer-icon-small:hover {
    /*transform: scale(1.2);*/
    background-color: var(--bg-button-hover-color);
    border-radius: 50%;
}




/*TEST*/

.icon-button {
    padding: 4px;
    height: 30px;
    width: 30px;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button-smaller {
    padding: 8px;
    height: 20px;
    width: 20px;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button-smaller-x {
    padding: 4px;
    height: 16px;
    width: 16px;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.icon-button-larger {
    padding: 4px;
    height: 36px;
    width: 36px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
}


.icon-button img, .icon-button-smaller img, .icon-button-larger img, .icon-button-smaller-x img {
    height: 100%;
}

/*.icon-button:hover img, .icon-button-smaller:hover img, .icon-button-larger:hover img, .icon-button-smaller-x:hover img {*/
/*    transform: scale(1.2);*/
/*}*/

.icon-button:hover, .icon-button-smaller:hover, .icon-button-larger:hover, .icon-button-smaller-x:hover {
    background-color: var(--bg-button-hover-color);
    border-radius: 50%;
}



.button-with-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.button-with-icon img {
    height: 20px;
}

.button-background {
    background-color: var(--bg-button-color);
    border-radius: 10px;
    padding: 2px 10px;
}

.button-background:hover {
    background-color: var(--bg-button-hover-color);
}

/*force line break in formula*/
.katex-display > .katex {
    white-space: normal !important;
}

code {
    white-space: pre-wrap !important;
    word-break: break-all !important;
}

pre {
    font-size: unset !important;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-anim {
    animation: spin 4s linear infinite;
}

@keyframes fade {
    0% { opacity: 1.0; }
    50% { opacity: 0.4; }
    100% { opacity: 1.0; }
}

.fade-anim {
    animation: fade 8s ease-in-out infinite;
}

@keyframes slide {
    0% { transform: translateX(-100%); }
    50% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}

.slide-anim {
    animation: slide 4s ease-in-out infinite;
}

@keyframes colorChange {
    0% { background-color: red; }
    50% { background-color: blue; }
    100% { background-color: red; }
}

.color-anim {
    animation: colorChange 4s ease-in-out infinite;
}

@keyframes flip {
    0% { transform: rotateY(0); }
    100% { transform: rotateY(360deg); }
}

.flip-anim {
    animation: flip 4s ease-in-out infinite;
}

@keyframes wobble {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
}

.wobble-anim {
    animation: wobble 1s ease-in-out infinite;
}

.icon {
    cursor: pointer;
    padding: 4px;
    /*height: 16px;*/
    /*width: 16px;*/
}

.icon img {
    height: 20px;
    width: 20px;
}

.icon:hover {
    background-color: var(--bg-button-hover-color);
    border-radius: 50%;
}

.title-with-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 10px;
}

/*.title-with-back img {*/
/*    height: 20px;*/
/*    cursor: pointer;*/
/*}*/


/*.title-with-icon {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 10px;*/
/*    align-items: center;*/
/*}*/

.modal-container {
    flex: 1;
}

.modal-header {

}


.modal-list-row {
    border-bottom: solid 1px var(--border-color);
    padding: 10px;
    cursor: pointer;
}

.modal-list-row:hover {
    background-color: var(--bg-button-hover-color);
}

.scroll-list-outside-window {
    height: 100%;
    overflow-y: auto;
}

.scroll-list-inner-container {

}

.button-panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.selection-list-item {
    cursor: pointer;
    padding: 8px 10px;
}

.selection-list-item:not(:last-child) {
    border-bottom: solid 1px var(--border-color);
}

.selection-list-item:hover {
    background-color: var(--bg-selection-item-hover-color);
}

.list-item {
    padding: 2px 10px;
}
.list-item:not(:last-child) {
    border-bottom: solid 1px var(--border-color);
}

.list-item:hover {
    background-color: var(--bg-selection-item-hover-color);
}

[contenteditable="true"]:empty:before {
    content: attr(data-placeholder);
    color: grey;
    pointer-events: none;
    display: block; /* For new lines */
}

.context-modal-margin {
    margin-top: 34px;
}

.title {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 2px;
}

.h-w-100 {
    height: 100%;
    width: 100%;
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
}

/*markdown*/
/*.markdown h1,*/
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown ul,
.markdown h6 {
    /* margin: top right bottom left */
    margin: 16px 0 8px 0;
    padding: 0;

    /*margin-block-start: 0.5em; !* Reduced space before the header *!*/
    /*margin-block-end: 0.5em; !* Reduced space after the header *!*/
}

.markdown p {
    /*margin-block-start: 1em; !* Top margin *!*/
    margin-block-end: 1em;   /* Bottom margin */
}

/*.markdown p:not(:first-child) {*/
/*    margin-block-start: 0;   !* Remove top margin for all paragraphs except the first one *!*/
/*}*/

.markdown ul, .markdown ol {
    /* padding: top right bottom left */
    padding: 0 0 0 26px;
    /* margin: top right bottom left */
    margin: 0 0 20px 0;
}
.markdown li {
    /* padding: top right bottom left */
    padding: 0 0 0 6px;
    /* top & bottom | left & right */
    margin: 8px 0;
}

.markdown pre {
    margin: 0;
}


.fa-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px; /* Adjust the size as needed */
    height: 16px; /* Adjust the size as needed */
    cursor: pointer;
    /*border-radius: 50%;*/
    transition: background-color 0.3s ease, transform 0.2s ease;
    background-color: transparent;
    /*border-radius: 50%;*/
    padding: 8px;
    /*box-sizing: border-box;*/
    border-radius: 50%;
    color: var(--icon-color);
}

.fa-icon.-smaller {
    width: 12px; /* Adjust the size as needed */
    height: 12px; /* Adjust the size as needed */
}
.fa-icon.-larger {
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
}
.fa-icon.-larger-x {
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
}
.fa-icon.-larger-xx {
    width: 32px; /* Adjust the size as needed */
    height: 32px; /* Adjust the size as needed */
}

.fa-icon.-emphasized {
    /*background-color: #6c7f9f;   !* #6c7f9f*!*/
    /*color: #fafdff;  !* #fafdff *!*/
    /*border-radius: 50%;*/
    color: var(--primary-color);
}



.fa-icon img, .fa-icon svg {
    width: 100%; /* Ensures the icon takes up the full width of the container */
    height: 100%; /* Ensures the icon takes up the full height of the container */
    object-fit: contain;
}

.fa-icon:hover {
    background-color: var(--bg-button-hover-color);
    /*background-color: rgba(0, 0, 0, 0.1); !* Light hover effect *!*/
    transform: scale(1.1); /* Slight zoom effect on hover */

    /*background-color: var(--bg-button-hover-color);*/
    border-radius: 50%;
    /*border-radius: 4px;*/
}


.fa-icon.-blue {
    background-color: var(--bg-icon-blue);
    color: var(--icon-color-white);
}

.fa-icon.-gray {
    background-color: var(--bg-icon-gray);
    /*color: var(--darker-secondary-color);*/
    animation: wobble 1s ease-in-out infinite;
}


.fa-icon.-color-blue {
    color: var(--main-color);
}

.fa-icon:active {
    transform: scale(0.95); /* Slight shrink effect when clicked */
}

.fa-icon.-accept {
    color: var(--icon-check-color);
}

.-main-color {
    color: var(--main-color);
}



