/*.content-modal {*/
/*    color: #8a8a8a; !* Light text *!*/
/*    background-color: #0c0c0c; !* Dark background *!*/
/*}*/
/*.content-dialog {*/
/*    min-width: 90%;*/
/*    min-height: 70%;*/
/*    padding: 10px;*/
/*    border: 1px solid #000;*/
/*    box-sizing: border-box;*/
/*    background-color: #3f3f3f; !* Dark background *!*/
/*    color: #dcdccc; !* Light text *!*/
/*    font-family: 'Courier New', monospace; !* Monospace font *!*/
/*    font-size: 0.8em; !* Smaller font size *!*/
/*}*/

.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    /*gap: 10px;*/
    margin: 10px;
}

.content-title-input {
    /*width: 90%;*/
}

.content-dialog-textarea {
    /*min-width: 80vh;*/
    /*min-height: 50vh;*/
    flex-grow: 1;
    /*width: 90%;*/
    padding: 4px 10px;
}

.content-content {
    width: 70%;
    max-width: 500px;
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    /*cursor: pointer;*/
    align-items: center;
}

/*.content-content:hover {*/
/*    background-color: var(--bg-lighter-color);*/
/*}*/

.contextList {
    /*gap: 5px;*/
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    align-items: stretch;
}

.is_shared_panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

