.home-container {
    /*padding: 0px 10px 0px 20px;*/
    /*background-color: #f2f2f2;*/
    display: flex;
    overflow: hidden;
    height: 100%;
}
.home-container-scroll {
    padding: 0px 10px 0px 50px;
    /*background-color: #f2f2f2;*/
    overflow-y: auto;
}
