.app-top {
    background-color: var(--bg-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    /*padding-inline: 10px;*/
}

@media only screen and (max-width: 768px) {
    .app-top {
        padding-inline: 0px;
    }
}

.top-title {
    cursor: pointer;
    color: var(--primary-color);
}

.top-level-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*padding: 5px;*/
    gap: 5px;
    align-items: center;
    min-height: 50px;
    height: fit-content;
    /*height: var(--topnav-height);  !*TODO: Ver se faz efeito..*!*/
    background: linear-gradient(74deg, var(--bg-color), var(--bg-secondary-color));
}

/*.project-switcher-option {*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*    max-width: 20px; !* Adjust this value to your needs *!*/
/*}*/

.top-level-main {
    /*flex-grow: 1;*/
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    z-index: auto;
    /*border-top: solid 1px var(--border-color);*/
}

.header-left {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
}
.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;   /*for mobile*/
    margin-right: 24px;
}

.header-middle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 40px;

}

.project-switcher {
    padding: 5px 2px;
    border-radius: 5px;
    border-color: var(--border-color);
}

/*.project-menu-item {*/
/*    margin-left: 10px;*/
/*}*/


.nav-icon {
    transition: transform 0.3s ease-in-out;
    /*z-index: 2;   !* more than this will be over the modal..*!*/
}
.nav-icon img {
    height: 30px;
}
.nav-icon:hover img {
    transform: scale(1.2);
}

.show-nav {
    /*position: fixed;*/
    cursor: pointer;
    /*left: 5px;*/
    /*top: 5px;*/
}
/*.hide-nav {*/
/*    !*position: relative;*!*/
/*    !*height: 0px;*!*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    height: 0px;*/
/*    !*right: 0px;*!*/
/*    cursor: pointer;*/
/*}*/

/* ------------- search modal -------------*/
.model-list-search-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.modal-list-search-input {
    flex: 1;
}

.modal-list-row {
    border: solid 1px var(--border-color);
    /*border-radius: 4px;*/
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 10px;
    cursor: pointer;
    background-color: var(--bg-secondary-color);
}

.modal-list-title {
    /*font-weight: bold;*/
    font-size: 0.92rem;
}

.modal-list-date {
    font-size: 0.8em;
}

.modal-list-content {
    font-size: 0.8em;
    word-wrap: anywhere;
}



.search-modal-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    /*min-height: 480px;*/
    /*min-width: 600px;*/
    height: 100%;
    /*max-width: 50%;*/
    margin-top: 30px;
    width: 320px;
}

@media (max-width: 768px) {
    .search-modal-list-container {
        width: 100%;
    }
}


.model-list-search-result-container {
    overflow: auto;
    flex: 1;
}

.model-list-search-result-inner-scroll {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.data-selection-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
    font-size: var(--font-size-small);
    width:450px;
}

.data-selection-list  div {
    /*border-right: solid 1px var(--border-color);*/
}

@media (min-width: 768px) {
    .data-selection-list {
        /*max-width: 500px;*/
    }
}

.data-selection-item {
    padding: 10px 0;
    /*border-bottom: solid 1px var(--border-color);*/
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.data-selection-item:not(:last-child) {
    border-bottom: solid 1px var(--border-color);
    /*border-bottom: 1px solid #000; !* Adjust the color and size as needed *!*/
}

.data-selection-item:hover {
    background-color: var(--bg-secondary-color-2);
    /*padding: 10px 0;*/
    /*border-bottom: solid 1px var(--border-color);*/
    /*cursor: pointer;*/
}

.data-selection-item-text {
    flex: 1;
}

.data-selection-item-text-lines {
    flex: 1;

    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*gap: 4px;*/
    /*width: 500px;*/
    display: grid;
    grid-template-columns: 2fr 12fr 2fr 2fr 3fr 2fr 3fr;
    grid-auto-flow: row; /* Items will flow into new rows */
    word-break: break-word;
    white-space: wrap;
}

@media (max-width: 768px) {
    /*.data-selection-item-text-lines {*/
    /*    width: 100%;*/
    /*}*/
    .data-selection-list {
        width: 100%;
    }
}

.title {
    /*font-size: var(--font-size-chat-title);*/
    /*color: var(--primary-color);*/
}

.right {
    text-align: right;
}
.center {
    text-align: center;
}

.title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: var(--font-size-default);
}

.title-row img {
    height: 24px;
    /*width: 30px;*/
}

.data-selection-item-description {
    font-size: smaller;
}

.data-store-selected {
    width: 30px;
    position: relative;
}

.data-store-selected img {
    position: absolute;
    height: 24px;
    width: 24px;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the image within the flex-child */
}
.data-selection-footnotes {
    margin: 0px 20px;
}

.use-model {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 0.85rem;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    /*color: var(--primary-color);*/
    margin-right: 10px;
}

.use-model img {
    height: 12px;
}

.temperature {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 0.85rem;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    margin-right: 10px;
}

.temperature img {
    height: 24px;
}

.temperature-modal-container {
    margin-top: 40px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 300px;
}

.temperature-description {
    color: var(--lightenerer-color);
    font-size: var(--font-size-chat-title);
}

@media only screen and (max-width: 768px) {
    .temperature-description {
        font-size: 1rem;
    }
}
.panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.models-annotation {
    font-family: monospace;
    font-size: smaller;
}

.models-field {
    font-weight: bold;
    color: var(--color-orandish);
}
