.loading-window {
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
