/*.content-modal {*/
/*    color: #8a8a8a; !* Light text *!*/
/*    background-color: #0c0c0c; !* Dark background *!*/
/*}*/
/*.content-dialog {*/
/*    min-width: 90%;*/
/*    min-height: 70%;*/
/*    padding: 10px;*/
/*    border: 1px solid #000;*/
/*    box-sizing: border-box;*/
/*    background-color: #3f3f3f; !* Dark background *!*/
/*    color: #dcdccc; !* Light text *!*/
/*    font-family: 'Courier New', monospace; !* Monospace font *!*/
/*    font-size: 0.8em; !* Smaller font size *!*/
/*}*/

.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    gap: 10px;
    margin: 10px;
}

.content-title-input {
    /*width: 90%;*/
}


.suggestion-modal-container {
    /*width: 400px;*/
    padding: 20px;
    /*background-color: #fff;*/
    border-radius: 8px;
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    height: 100%;
}

@media only screen and (min-width: 768px) {
    .suggestion-modal-container {
        min-height: 60vh;
        min-width: 70vh;
    }
}
/* Title of the modal */
.suggestion-modal-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    /*color: #333;*/
}

/* Row container for each input section */
.suggestion-modal-row {
    display: flex;
    /*align-items: center;*/
    /*margin-bottom: 15px;*/
    gap: 10px;
}

.suggestion-modal-row-main {
    flex: 5;
    height: 100%;
}



/* Title for each input section */
.suggestion-modal-title {
    flex: 1;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /*font-size: 16px;*/
    /*color: #555;*/
}

/* Input container */
.suggestion-modal-input {
    flex: 2;
}

/* Select input styling */
.suggestion-modal-input select {
    width: 100%;
    padding: 8px;
    /*font-size: 14px;*/
    border: 1px solid var(--border-color);
    /*border-radius: 4px;*/
}

.suggestion-modal-attachment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Textarea styling */
.suggestion-modal-input textarea {
    /*width: 400px;*/
    /*height: 256px;*/
    padding: 4px;
    box-sizing: border-box;
    /*font-size: 14px;*/
    border: 1px solid var(--border-color);
    border-radius: 4px;
    /*resize: vertical;*/
    width: 100%;
    height: 100%;
    min-height: 100px;
}

.image-in-thread {
    /*border-radius: 16px;*/
    height: 48px;
    max-width: 100%;
}

.attachment-container {
    border: dotted 1px var(--border-color);
    display: flex;
    flex-direction: column;
}

.suggestion-modal-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.row-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}


.icon {
    cursor: pointer;
    padding: 4px;
    /*height: 16px;*/
    /*width: 16px;*/
}

.icon img {
    height: 20px;
    width: 20px;
}

.icon:hover {
    background-color: var(--bg-button-hover-color);
    border-radius: 2px;
}

.contextList {
    /*gap: 5px;*/
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
}


.content-grid-row {
    display: grid;
    grid-template-columns: 50px 2fr 100px 1fr;
    gap: 10px;
}

@media (max-width: 768px) {
    .content-grid-row {
        display: block;
    }

    /*.table-row {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 10px;*/
    /*}*/

    /*.table-cell {*/
    /*    margin-bottom: 5px;*/
    /*}*/
}

.attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}

.container-grid-header-row {
    border-bottom: solid 1px var(--border-color);
}

@media (max-width: 768px) {
    .container-grid-header-row {
        display: none;
    }
}

.header {
    font-weight: bold;
    /*background-color: #f0f0f0;*/
    padding: 10px;
}

.comment-entry div{
    color: var(--main-color);
}

.comment-entry{
    border-bottom: dashed 1px var(--border-color);
    margin: 4px 0;
}
.comment-entry:last-child{
    border-bottom: none;
}

.comment-meta {
    font-size: smaller;
    opacity: 0.7;
}
