.loading-window {
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    opacity: 0.7;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /*background-color: var(--fullscreen-background-lighter5);*/
}

.loading-img {
    width: 50px;
    height: 50px;
}