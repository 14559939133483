.candidate-window {
    position: fixed;
    left: 10%;
    top: 10%;
    right: 40%;
    bottom: 30%;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-darker);
    border: solid 1px var(--border-color);
    padding: 10px;
    margin: 10px;
    z-index: 1010;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .candidate-window {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.candidate-output-container {
    flex: 1;
    overflow-y: auto;
    margin: 10px;
}


.candidate-panel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}


.container {
}

.assistant-panel {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    opacity: 0.7;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.assistant-panel img {
}

.assistant-wrapper {
    position: relative;
    /*display: inline-block;*/
}
