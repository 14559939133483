/*!* src/login/Login.css *!*/

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #000000; /* Black background */
}

/*.waveText {*/
/*    font-size: 3rem; !* Adjust the size as needed *!*/
/*    color: #92adda;*/
/*    background: linear-gradient(90deg, #92adda 50%, #1f1f2a 50%);*/
/*    font-family: fantasy;*/
/*    background-size: 200% 100%;*/
/*    background-clip: text;*/
/*    -webkit-background-clip: text;*/
/*    color: transparent;*/
/*    animation: wave 5s infinite linear;*/
/*}*/

/*@keyframes wave {*/
/*    0% {*/
/*        background-position: 100% 0;*/
/*    }*/
/*    100% {*/
/*        background-position: -100% 0;*/
/*    }*/
/*}*/

.waveText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waveText div {
    /*font-family: fantasy;*/
    font-weight: bold;
    font-size: 3rem; /* Adjust the size as needed */
    /*color: #92adda;*/
    /*background: linear-gradient(90deg, transparent, rgba(146, 173, 218, 0.7), transparent);*/
    background: linear-gradient(90deg, transparent, rgba(146, 173, 218, 0.7), #92adda, #92adda, #ffffff);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /*color: #92adda;*/
    /*animation: lightWave 5s cubic-bezier(0.0, 0.0, 0.2, 1) 1;*/
    /*animation: lightWave 5s ease-out 1;*/
    animation: lightWave 5s linear 1;
}

@keyframes lightWave {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -100% 0;
    }
}



/*.login-form {*/
/*    width: 300px;*/
/*    text-align: center;*/
/*    !*background-color: white;*!*/
/*    !*padding: 20px;*!*/
/*    !*border-radius: 8px;*!*/
/*    !*box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);*!*/
/*}*/

/*.form-group {*/
/*    margin-bottom: 5px;*/
/*}*/

/*.form-group label {*/
/*    display: block;*/
/*    margin-bottom: 5px;*/
/*}*/

/*.form-control {*/
/*    width: 100%;*/
/*    !*padding: 10px;*!*/
/*    !*border-radius: 4px;*!*/
/*    border: 1px solid #ddd;*/
/*}*/

/*.submit-btn {*/
/*    width: 50%;*/
/*    padding: 10px;*/
/*    !*border-radius: 4px;*!*/
/*    border: none;*/
/*    !*background-color: #007BFF;*!*/
/*    !*color: white;*!*/
/*    cursor: pointer;*/
/*}*/

/*.submit-btn:hover {*/
/*    !*background-color: #0056b3;*!*/
/*}*/

/*.message {*/
/*    margin-top: 15px;*/
/*}*/



/*.login-container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    height: 100vh;*/
/*    !*background-color: #f0f0f0;*!*/
/*}*/

/*.login-container div {*/
/*    width: 300px;*/
/*    padding: 20px;*/
/*    border-radius: 5px;*/
/*    !*box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);*!*/
/*    !*background-color: white;*!*/
/*}*/

/*.login-container label {*/
/*    display: block;*/
/*    margin-bottom: 5px;*/
/*    !*font-weight: bold;*!*/
/*}*/

/*.login-container input {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    margin-bottom: 20px;*/
/*    border: 1px solid #ddd;*/
/*    !*border-radius: 5px;*!*/
/*}*/

/*.login-container button {*/
/*    width: 100%;*/
/*}*/

/*.login-container span {*/
/*    !*display: block;*!*/
/*    !*margin-top: 10px;*!*/
/*    !*color: #007BFF;*!*/
/*}*/

/*.login-container p {*/
/*    !*color: red;*!*/
/*    text-align: center;*/
/*}*/

.logo {
    height: 100px;
    width: 100px;
    border-radius: 20%;
}