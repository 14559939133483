.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.header-line {
    border-bottom: solid 1px var(--border-color);
}

.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
}




.container {
    overflow-y: auto;
    margin: 10px 40px;
}

.chat-conversation-top {
    background-color: var(--bg-secondary-color);
}

.chat-conversation-top > div  {
    margin: 2px 0; /* This reduces the space between paragraphs */
    padding: 10px;
    border-radius: 4px; /* This adds rounded corners */
    white-space: pre-wrap;
    word-break: break-word;
    /*width: 80%;*/
}

.message-entry-line {
}

.message-entry-line.assistant {
    color: var(--lighter-secondary-color);
}

.message-entry-line.user {
    background-color: var(--bg-chat-user); /* Light pinkish-red color */
}

.message-entry-line.system {
    background-color: var(--bg-chat-system); /* Light pinkish-red color */
    color: var(--chat-user-color);
}

.message-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.image-in-thread {
    border-radius: 16px;
    max-height: 160px;
    max-width: 100%;
}

.role {
    font-weight: bold;
}

.published {
    margin: 10px;
    /*font-size: larger;*/
    color: var(--darker-secondary-color);
    font-weight: bold;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.markdown-parent {
    white-space: normal !important;
}

