.container {
    height: 100%;
    overflow: auto;
}

.container-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.container-grid-row {
    display: grid;
    grid-template-columns: 50px 50px 100px 3fr 100px 1fr;
    gap: 10px;
}

@media (max-width: 768px) {
    .container-grid-row {
        display: block;
    }

    /*.table-row {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 10px;*/
    /*}*/

    /*.table-cell {*/
    /*    margin-bottom: 5px;*/
    /*}*/
}

.container-grid-header-row {
    border-bottom: solid 1px var(--border-color);
}

@media (max-width: 768px) {
    .container-grid-header-row {
        display: none;
    }
}

.header {
    font-weight: bold;
    /*background-color: #f0f0f0;*/
    padding: 10px;
}

.attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}

.comment-textarea {
    min-height: 120px;
    min-width: 250px;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
}

.comment-entry div{
    color: var(--main-color);
}

.comment-entry{
    border-bottom: dashed 1px var(--border-color);
    margin: 4px 0;
}
.comment-entry:last-child{
    border-bottom: none;
}

.comment-meta {
    font-size: smaller;
    opacity: 0.7;
}
